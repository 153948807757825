<template>
  <moe-page title="">
    <moe-card class="mb-20" :autoHeight="true" title="签到记录">
      <moe-inquire @search="signSearch">
        <el-form-item label="用户编号">
          <el-input :value="signParams.userId" @input="(value) => signParams.userId = $moe_formatter.formatterInteger(value)" placeholder="输入用户编号" maxlength="100" clearable />
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model.trim="signParams.userName" placeholder="输入昵称" maxlength="100" clearable />
        </el-form-item>
        <el-form-item label="签到时间">
          <el-date-picker
            style="width: 100%;"
            placement="bottom-start"
            v-model="datetime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="(datetime) => ([signParams.startTime, signParams.endTime] = datetime || ['', ''])" />
        </el-form-item>
      </moe-inquire>

      <moe-table ref="signTableRef" url="/shop/sign/recordList" :params="signParams" :numberShow="false">
        <el-table-column label="用户编号" prop="userId" min-width="80" />
        <el-table-column label="用户昵称" prop="userName" min-width="150" />
        <el-table-column label="签到时间" prop="signTime" min-width="150" />
        <el-table-column label="连签天数" prop="signDays" min-width="150" />
        <el-table-column label="获得奖励" prop="prizeType" min-width="150" :formatter="formatterPrizeType" />
      </moe-table>
    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: 'SignManageRecord',
  data() {
    return {
      signParams: {
        pageNum: 1,
        pageSize: 10,
        userName: '',
        userId: '',
        startTime: '',
        endTime: '',
      },
      datetime: [],
    }
  },
  methods: {
    formatterPrizeType({ prizeType, signDays, coupon }) {
      if (prizeType) {
        // let detail = prizeType === 'COUPON' ? `连签${signDays}天专属优惠券` : prizeType === 'GIFT' ? `连签${signDays}天专属赠品` : ``
        let detail = prizeType === 'COUPON' ? `签到${coupon?.discount}元专属优惠券` : prizeType === 'GIFT' ? `签到专属赠品` : ``
        return `${this.$moe_format.getPrizeTypeList(prizeType)}: ${detail}`
      } else {
        return '无'
      }
    },
    signSearch(isSearch) {
      if (!isSearch) {
        this.signParams = {
          pageNum: 1,
          pageSize: 10,
          userName: '',
          userId: '',
          startTime: '',
          endTime: '',
        }

        this.datetime = [];
      }

      this.$refs['signTableRef'].searchData();
    }
  }
}
</script>